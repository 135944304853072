/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
    	  if ($('.image-slider')) {
	    	  $('.image-slider').flexslider({
		         animation: "fade",
		         slideshowSpeed: 5000,
		         animationSpeed: 1500,
		         controlNav: true,
		         directionNav: false 
		      });
    	  }
    	  
    	  var selectedFont = localStorage.getItem('selectedFontSize');
		  if (selectedFont === "bigger") {
			  $('.bigger-size').addClass("active-font");
			  $('body').addClass("zoom-bigger");
		  } else if (selectedFont === "biggest") {
			  $('.biggest-size').addClass("active-font");
			  $('body').addClass("zoom-biggest");
		  }
		  else {
			  $('.normal-size').addClass("active-font");
			  
		  }
    	  
    	  $('.size-selector').click(function() {
    		 return false; 
    	  });
    	  
    	  $('.normal-size').click(function() {
    		  $('.active-font').removeClass("active-font");
    		  $('.normal-size').addClass("active-font");
    		  localStorage.setItem('selectedFontSize', null);
    		  $('body').removeClass("zoom-biggest");
    		  $('body').removeClass("zoom-bigger");
     	  });
    	  
    	  $('.bigger-size').click(function() {
    		  $('.active-font').removeClass("active-font");
    		  $('.bigger-size').addClass("active-font");
    		  localStorage.setItem('selectedFontSize', 'bigger');
    		  $('body').removeClass("zoom-biggest");
    		  $('body').addClass("zoom-bigger");
     	  });
    	  
    	  $('.biggest-size').click(function() {
    		  $('.active-font').removeClass("active-font");
    		  $('.biggest-size').addClass("active-font");
    		  localStorage.setItem('selectedFontSize', 'biggest');
    		  $('body').removeClass("zoom-bigger");
    		  $('body').addClass("zoom-biggest");
     	  });    	  
    	  
    	  
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    'single_tribe_events': {
      init: function() {
    	  $('.archive-carousel').carousel({
    		  interval: 5000 
    	  });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
